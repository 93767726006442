export const checkCache = async <T>(key: string): Promise<T | undefined> => {
	if (typeof window === 'undefined') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { getRedisClient } = require('@vakantiesnl/services/src/redis/client');
		try {
			const client = await getRedisClient();
			if (client.status === 'ready') {
				const rawData = await client.get(`next:v1:${key}`);
				if (rawData) {
					return JSON.parse(rawData);
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error fetching Redis cache: ', err);
		}
	}
};

export const storeInCache = async (key: string, data: Record<string, unknown>): Promise<void> => {
	if (typeof window === 'undefined') {
		try {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const { getRedisClient } = require('@vakantiesnl/services/src/redis/client');
			const client = await getRedisClient();
			// Expire in 10 minutes
			if (client.status === 'ready') {
				await client.setex(`next:v1:${key}`, 60 * 10, JSON.stringify(data));
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error storing data in Redis cache: ', err);
		}
	}
};
